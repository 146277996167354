<template>
  <div id="app">
    <keep-alive>
      <router-view
        v-if="$route.meta.keepAlive"
        class="main-router"
      ></router-view>
    </keep-alive>
    <router-view
      v-if="!$route.meta.keepAlive"
      class="main-router"
    ></router-view>
  </div>
</template>

<script>
 
export default {
  name: 'App',
}
</script>

<style lang="less">
@import "./styles/ele-reset.less";
#app {
  height: 100vh;
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  // margin-top: 60px;
}

.main-router {
  width: 100%;
  // height: 100%;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  // display: none;
  width: 5px;
  height: 5px;
  background-color: #cdcccc42;
}

::-webkit-scrollbar-x {
  display: none;
}

::-webkit-scrollbar-track {
  height: 100px;
  box-shadow: 0 0 6px rgba(226, 225, 225, 0);
  background-color: #e1dcdc;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0,0,0,0);
  background-color: #ff8e0a;
  border-radius: 10px;
}
@media screen and (max-width: 960px) {
  ::-webkit-scrollbar {
    display: none;
  }
}
</style>
