import Vue from "vue";
import Vuex from "vuex";
import request from '@/utils/request'
import { 
  submitUserFeedback
} from '@/utils/api'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {
    submitUserFeedback({ commit, state }, params) {
      return new Promise((resolve, reject) => {
        request.post(submitUserFeedback, params).then(res => {
          if (res.status == 200) {
            resolve(res)
          }
        }).catch(err => {
          reject()
        })
      })
    },
  },
  modules: {}
});