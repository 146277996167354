import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        redirect: '/home',
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/index')
    },
    {
        path: "/index",
        name: "index",
        component: () => import("@/views/index/index"),
    },
    {
        path: "/clockin",
        name: "clockin",
        component: () => import("@/views/clockin/clockin"),
    },
];


const router = new VueRouter({
    base: '/',
    mode: 'history',
    scrollBehavior: () => ({
        y: 0,
    }),
    routes,
});


router.beforeEach(async (to, from, next) => {
    next()
});

router.onError((error) => {
    print(error);
});


export default router;