import axios from "axios";
import qs from "qs"

axios.defaults.baseURL = "http://47.88.12.248:8082";



// 请求拦截器
axios.interceptors.request.use(
  config => {
    if (config.method === 'get') {
      // 如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
      config.paramsSerializer = function (params) {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      }
    }
    config.headers['Access-Control-Allow-Origin'] = "*";
    config.headers['Access-Control-Allow-Methods'] = "GET, POST, OPTIONS,DELETE";
    config.headers['Access-Control-Allow-Headers'] = "DNT,X-Mx-ReqToken,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type,Authorization";
    return config;
  },
  error => {
    return Promise.error(error);
  }
)

// 响应拦截器
axios.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    if (error.response && error.response.status) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(error.message)
    }
  }
);

function get(url, data, config) {
  let newData = {}
  for (let index in data) {
    newData[index.replace(/_/ig, "")] = data[index]
  }
  return unionRequest(url, null, {
    ...config,
    params: newData
  })
}

function post(url, data, config,params) {
  return unionRequest(url, data, { ...config, params }, 'post');
}

function unionRequest(url, data, config, method = "get") {
  return new Promise((resolve, reject) => {
    let promise = null
    if (method == "get") {
      promise = axios[method](url, config)
    } else {
      promise = axios[method](url, data, config)
    }
    promise.then((res) => {
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

export default {
  get,
  post
}